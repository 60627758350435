var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"YWtiN9fFVeWUqB-RfX9ge"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const NEXT_PUBLIC_VERSION = process.env.NEXT_PUBLIC_VERSION || '2.0';

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn:
            SENTRY_DSN ||
            'https://e0bd3e2525fe4ccd9bb11f97ff324405@o4505103311568896.ingest.sentry.io/4505103312945152',
        // Adjust this value in production, or use tracesSampler for greater control
        tracesSampleRate: 1.0,
        release: NEXT_PUBLIC_VERSION,
        // ...
        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps
    });
}
