import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';

interface IModal {
    show: boolean;
    title?: string;
    afterClose: () => void;
    closeable?: boolean;
    styles?: React.CSSProperties;
}

export const Modal: React.FC<IModal> = (props) => {
    const { children, show, title, afterClose, closeable, styles = {} } = props;

    return (
        <>
            {show && (
                <div
                    className="fixed left-0 top-0 z-50 flex h-screen w-screen bg-black/70 shadow-xl backdrop-blur-sm"
                    data-test-id="modal-bg"
                    id="modal-bg"
                />
            )}
            <Transition appear show={show} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-[10000] h-full w-full overflow-y-auto"
                    onClose={() => afterClose()}
                >
                    <div className="relative flex h-screen max-h-[100svh] w-screen items-center justify-center p-2 text-center sm:p-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div
                                style={{
                                    ...styles,
                                }}
                                className="relative flex h-max max-h-[80svh] w-full max-w-2xl transform flex-col rounded-2xl bg-white p-2 shadow-xl transition-all md:p-4"
                            >
                                <Dialog.Title
                                    as="h3"
                                    className="absolute left-0 top-0 w-full pt-2 text-center font-serif text-lg font-medium leading-6 text-primary"
                                >
                                    {title ?? ''}
                                </Dialog.Title>

                                {closeable && (
                                    <div className="relative flex h-8 w-full items-end justify-end">
                                        <button
                                            className="relative h-8 w-8 rounded-full bg-transparent p-1 transition-all hover:bg-secondary hover:text-white"
                                            onClick={() => afterClose()}
                                        >
                                            <XIcon />
                                        </button>
                                    </div>
                                )}

                                <div
                                    style={{
                                        ...styles,
                                    }}
                                    className="relative mt-5 flex max-h-screen w-full max-w-2xl flex-col overflow-y-auto overflow-x-hidden p-2 text-left align-middle"
                                >
                                    {children}
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

Modal.defaultProps = {
    closeable: false,
    title: '',
};
